import React from 'react'
import Navbar from '../components/Navbar'


function SignUp() {
  return (
    <>
    <div className='w-[100vw] bg-black h-[100vh] text-white'>
        <Navbar />
        <div className='flex flex-col items-center m-4 space-y-2'>
          <h1 className='font-bold text-[#ff9a02] text-2xl md:text-3xl'>Sign Up for Exclusive Content</h1>
          {/* <img className="w-[70vw] rounded-sm py-6" src={homePic} alt="me" /> */}
          <p className='text-center'>At only $69 per month, you too can enjoy all the exclusive content your heart desires</p>
          <input className="w-[80vw]  md:w-[50vw] rounded-sm py-2 text-black" placeholder="Name" />
          <input className="w-[80vw]  md:w-[50vw] rounded-sm py-2 text-black" placeholder="Email" />
          <input className="w-[80vw]  md:w-[50vw] rounded-sm py-2 text-black" placeholder="Phone Number" />
          <input className="w-[80vw]  md:w-[50vw] rounded-sm py-2 text-black" placeholder="Home Address" />
          <input className="w-[80vw]  md:w-[50vw] rounded-sm py-2 text-black" placeholder="Credit Card Number" />
          <input className="w-[80vw]  md:w-[50vw] rounded-sm py-2 text-black" placeholder="Last Four Digits of SSN" />
          <input className="w-[80vw]  md:w-[50vw] rounded-sm py-2 text-black" placeholder="Mothers Maiden Name" />
          <button className='w-[80vw] md:w-[50vw]  border-2 py-2 rounded-sm'>SIGN UP</button>
        </div>
    </div>
    </>
  )
}

export default SignUp