import React from 'react'
import Navbar from '../components/Navbar'
import bathroom from '../assets/bathroom.png'


function LiveCam() {
  return (
    <>
    <div className='w-[100vw] bg-black h-[100vh] text-white'>
        <Navbar />
        <div className='flex flex-col items-center mx-4 space-y-2'>
          <h1 className='font-bold text-[#ff9a02] text-2xl md:text-3xl pt-6'>24/7 Shower Camera</h1>
          <p className='text-center md:text-2xl md:w-[60%]'>
            After receiving a lot of requests, I've decided to install a shower camera in my bathroom that never stops recording.
          </p>
          <img className="w-[90vw] md:w-[25vw] rounded-sm py-6" src={bathroom} alt="me" />
        </div>
    </div>
    </>
  )
}

export default LiveCam