import React, { useState } from 'react';
import {
  FaBars,
  FaTimes,
} from 'react-icons/fa';

function Navbar() {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div className='flex flex-col items-center h-fit w-[100vw]'>
       <div className='flex space-x-1 bg-black w-fit rounded-lg border-2 border-black my-4 md:text-5xl text-3xl font-bold'>
        <p className=' text-white border-2 border-black rounded-lg'>JED AFTER</p>
        <p className=' border-2 border-[#ff9a02] text-black bg-[#ff9a02] rounded-[4px]'>DARK</p> 
       </div>

       <ul className='hidden text-xl space-x-2 md:flex w-[80%] justify-evenly'>
          <li className='border-b-2 border-[#ff9a02]'><a href="./">Home</a></li>
          <li className='border-b-2 border-[#ff9a02]'><a href="./About">About Me</a></li>
          <li className='border-b-2 border-[#ff9a02]'><a href="./FreePics">Free Pics</a></li>
          <li className='border-b-2 border-[#ff9a02]'><a href="./LiveCam">Live CAM Sessions</a></li>
          <li className='border-b-2 border-[#ff9a02]'><a href="./SignUp">Sign Up</a></li>
       </ul>
       <div onClick={handleClick} className='md:hidden z-10'>
        {!nav ? <FaBars /> : <FaTimes />}
      </div>

      {/* mobileMenu */}
      <ul
        className={
          !nav
            ? 'hidden'
            : 'absolute top-0 left-0 w-full h-screen bg-[#ff9a02] flex flex-col justify-center font-bold items-center text-black text-2xl space-y-4'
        } >
          <li><a href="./">Home</a></li>
          <li><a href="./About">About Me</a></li>
          <li><a href="./FreePics">Free Pics</a></li>
          <li><a href="./LiveCam">Live CAM Sessions</a></li>
          <li><a href="./SignUp">Sign Up</a></li>
        </ul>
    </div>
  )
}

export default Navbar