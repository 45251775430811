import React from 'react'
import Navbar from '../components/Navbar'

function AboutMe() {
  return (
    <>
    <div className='w-[100vw] bg-black sm:h-fit pb-10 md:h-[100vh]  text-white'>
        <Navbar />
        <div className='flex items-center justify-center'>
        <div className='flex flex-col items-center justify-center m-6 space-y-2 md:w-[50%]'>
            <h2 className='font-bold text-[#ff9a02] text-2xl md:text-3xl'>About Me</h2>
            <p className='md:text-xl'>&nbsp;&nbsp;&nbsp;&nbsp;Welcome to Jed After Dark, where I celebrate the beauty and uniqueness of the human body. 
                I am passionate about expressing myself through the medium of nude photography and videography. 
                I believe that every body is a work of art, and by sharing my sexy ass physique, I hope to 
                inspire others to embrace their own natural beauty and self-expression.</p>
            <p className='md:text-xl'>&nbsp;&nbsp;&nbsp;&nbsp;For me, this journey is not just about showcasing my pretty-boy face or rock hard pectorals, but it's also about cultivating 
                a deep sense of self-love and appreciation for the human form in all its shapes and sizes. 
                I find great joy in creating content that showcases the elegance, grace, and strength that 
                can be found in each curve, line, and contour of my body.</p>
            <p className='md:text-xl'>&nbsp;&nbsp;&nbsp;&nbsp;I am grateful for the community of fans who share my love for artistic expression and 
                body positivity. Your support and enthusiasm inspires me to continue exploring new ways of 
                showcasing myself. Thank you for joining me on this journey, 
                and I look forward to creating even more captivating content for you to enjoy after dark if you know what I mean.</p>
        </div>
        </div>
    </div>
    </>
  )
}

export default AboutMe