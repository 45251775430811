import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import AboutMe from './pages/AboutMe';
import FreePics from './pages/FreePics';
import SignUp from './pages/SignUp';
import LiveCam from './pages/LiveCam';

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<AboutMe />} />
        <Route path="/FreePics" element={<FreePics />} />
        <Route path="/SignUp" element={<SignUp />} />
        <Route path="/LiveCam" element={<LiveCam />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
