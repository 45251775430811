import React from 'react'
import Navbar from '../components/Navbar'
import homePic from '../assets/homePic.png'

function Home() {
  return (
    <>
    <div className='w-[100vw] bg-black h-[100vh] text-white'>
        <Navbar />
        <div className='flex flex-col items-center m-4 space-y-2'>
          <h1 className='font-bold text-[#ff9a02] text-2xl md:text-3xl '>Welcum You Horny Bastards!</h1>
          <img className="w-[70vw] md:w-[25vw] rounded-sm py-6" src={homePic} alt="me" />
          <p className='text-center md:w-[50%] md:text-xl'>You have reached the exclusive online haven of Jed After Dark, where you'll find an un paralleled collection of slutty
            photos and sizzling videos that are bound to captivate your senses. Rest assured, you won't find anything quite like my content elsewhere.
            So, go ahead and indulge in the alluring world of Jed After Dark. I can't wait to share my passion 
            (and nude body) with you. Enjoy!</p>
        </div>
    </div>
    </>
  )
}

export default Home