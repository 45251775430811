import React from 'react'
import Navbar from '../components/Navbar'
import homePic from '../assets/homePic.png'
import jed1 from '../assets/jed1.png'
import jed2 from '../assets/jed2.png'
import jed3 from '../assets/jed3.png'
import jed4 from '../assets/jed4.png'
import jed5 from '../assets/jed5.png'


function FreePics() {


  return (
    <>
    <div className='w-[100vw] bg-black h-fit text-white'>
        <Navbar />
        <div className='flex flex-col items-center mx-4 space-y-2 md:py-4'>
            <h1 className='font-bold text-[#ff9a02] text-2xl md:text-3xl pt-6'>Free Content ;)</h1>
            <p className='text-center md:text-2xl md:w-[60%]'>I know not everyone can afford my premium services,
            so please enjoy these free pics XD rawr</p>
            <div className='md:flex flex-wrap justify-evenly'>
                <img className="w-[80vw] md:w-[30vw] rounded-sm py-2" src={jed1} alt="me" /> 
                <img className="w-[80vw] md:w-[30vw] rounded-sm py-2" src={jed3} alt="me" />
                <img className="w-[80vw] md:w-[30vw] rounded-sm py-2" src={jed5} alt="me" /> 
                <img className="w-[80vw] md:w-[30vw] rounded-sm py-2" src={jed4} alt="me" />
                <img className="w-[80vw] md:w-[30vw] rounded-sm py-2" src={jed2} alt="me" />
                <img className="w-[80vw] md:w-[30vw] rounded-sm py-2" src={homePic} alt="me" />
            </div>
        </div>
    </div>
    </>
  )
}

export default FreePics